import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import * as React from "react";
import { Button, Container, Form, Row } from "react-bootstrap";
import PasswordStrengthBar from "react-password-strength-bar";
import Layout from "../components/Layout";
const eye = <FontAwesomeIcon icon={faEye} />;
export default function InfluencerWelcomePage() {
  const [passwordShown, setPasswordShown] = React.useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [password, setPassword] = React.useState("");
  
  return (
    <Layout>
      <main>
        <Container>
          <section>
            <div className="col-xl-4 col-lg-4 col-md-12 pb-5 pt-3 mx-auto text-center">
              <div className="px-5">
              <img src="../img/influencer-register.svg" alt="register" />
                <h5 className="fw-bold fs-26 my-4 px-5">
                  Register as Influencer
                </h5>
                <p>One last step before we welcome you</p>
              </div>
              <Form className="mt-4 text-start">
                <Form.Group controlId="formGridEmail" className="mb-4">
                  <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                    Full name
                  </Form.Label>
                  <Form.Control type="text" />
                </Form.Group>

                <Form.Group controlId="formGridEmail" className="mb-4">
                  <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                    E-mail address
                  </Form.Label>
                  <Form.Control type="email" />
                </Form.Group>

                <Form.Group
                  controlId="formGridPassword"
                  className="mb-4 pass-wrapper"
                >
                  <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                    Create password
                  </Form.Label>
                  <Form.Control
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={passwordShown ? "text" : "password"}
                  />
                  <i  role="button" onClick={togglePasswordVisiblity} aria-hidden="true">{eye}</i>
                  <PasswordStrengthBar
                    scoreWordStyle={true}
                    barColors={false}
                    password={password}
                    shortScoreWord="Short"
                    className="text-uppercase"
                  />
                </Form.Group>
                <Form.Group controlId="formGridEmail" className="mb-4">
                  <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                    Age
                  </Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
                <Button
                  variant="primary"
                  className="w-100 fs-14 fw-bold h-52"
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            </div>
          </section>
        </Container>
        <Container fluid={true} className="py-xl-5">
          <section className="Light-bg my-5">
            <Row>
              <div className="col-xl-6 col-lg-6 col-md-12 ps-0 bg-last-section">
                <img src="../img/Onboarding.svg" alt="bg" />
              </div>
              <div className="col-xl-5 col-lg-6 col-md-12 pt-xl-5 pt-lg-5">
                <h4 className="fs-40 fw-bold mb-5">Are you a merchant?</h4>
                <h5 className="bogo-par fw-normal lh-32 mb-5 fw-airlight pe-5 me-5">
                  Increase foot traffic and sustainably grow your profitability
                  with The BOGO App’s Dynamic Campaign Management™ system
                </h5>
                <Link to="#" className="fs-18 link-color pb-2">
                  BOGO for Business{" "}
                 <img src="../img/arrow-right.svg" alt="arrow right" className="ms-3" />
                </Link>
              </div>
            </Row>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
